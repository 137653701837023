import { Box, Image } from "@chakra-ui/react";

const FooterLogo = ({ logo, caption }) => {
  return (
    <Box pt={12} margin="0 auto" width={{ sm: "100%", md: "50%" }}>
      <Image src={logo} alt={caption} />
    </Box>
  );
};

export default FooterLogo;

/* eslint-disable no-undef */
import axios from "axios";
import { decode } from "jsonwebtoken";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import * as Sentry from "@sentry/nextjs";
import originalFetch from "isomorphic-fetch";
import fetchBuilder from "fetch-retry";

import packageJson from "../../package.json";
import authReducer, { STATUSES } from "./authReducer";

export const ROLES = {
  seller: "seller",
  customer: "customer",
};

const ApiContext = React.createContext();

const STORAGE_KEYS = {
  token: "auth:token",
  refreshToken: "auth:refreshToken",
  amplify: "amplify-signin-with-hostedUI",
};

let http;

let fetchWithRetries;

function ApiProvider({ config, children }) {
  const [state, dispatch] = useReducer(authReducer, {
    config,
    status: "pending",
    userId: null,
  });
  const [sessionId, setSessionId] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [sessionPin, setSessionPin] = useState(null);
  const [scopes, setScopes] = useState([]);

  const apiBaseUrl = config.apiBaseUrl;
  const clientId = config.clientId;

  if (!fetchWithRetries) {
    const options = {
      retries: 3,
      retryDelay: 1000,
      // retryOn: [419, 503, 504],
    };
    fetchWithRetries = fetchBuilder(originalFetch, options);
  }

  if (!http) {
    http = axios.create({
      baseURL: apiBaseUrl,
      timeout: 50000,
      headers: {
        "X-Client-Name": packageJson.name,
        "X-Client-Version": packageJson.version,
      },
    });

    http.interceptors.request.use((request) => {
      const token = sessionStorage.getItem(STORAGE_KEYS.token);

      if (token) {
        request.headers["Authorization"] = `jwt ${token}`;
      }

      return request;
    });

    http.interceptors.response.use(undefined, async (error) => {
      const response = error.response;
      if (
        response &&
        response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        if (!sessionStorage.getItem(STORAGE_KEYS.refreshToken)) {
          error.config.__isRetryRequest = true;
          return Promise.reject(error);
        }
        try {
          const result = await fetch(apiBaseUrl + "/auth/jwt", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({
              refreshToken: sessionStorage.getItem(STORAGE_KEYS.refreshToken),
            }),
          });
          if (result.status === 200 || result.status === 201) {
            const { token, refreshToken } = await result.json();
            setTokens({ token, refreshToken });
          } else {
            await clearTokens();
          }
        } catch (authError) {
          Sentry.captureException(authError);
          error.config.__isRetryRequest = true;
          await clearTokens();
          return Promise.reject(authError);
        }

        // retry the original request
        error.config.__isRetryRequest = true;
        Sentry.captureException(error);
        return http(error.config);
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    });
  }

  const setTokens = ({ token, refreshToken }) => {
    setScopes(decode(token).scopes);
    sessionStorage.setItem(STORAGE_KEYS.token, token);
    sessionStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
    console.log("set tokens", { token, refreshToken });
  };

  const verifyTokens = async () => {
    const token = sessionStorage.getItem(STORAGE_KEYS.token);
    const refreshToken = sessionStorage.getItem(STORAGE_KEYS.refreshToken);
    if (!token || !refreshToken) {
      dispatch({ type: "rejected", action: {} });
      return;
    }

    dispatch({ type: "pending", action: {} });
    setScopes(decode(token).scopes);
    try {
      const response = await http(apiBaseUrl + `/auth/me`);
      if (response.data && !response.data.data.profile_completed) {
        dispatch({
          type: "profile-not-completed",
          action: {
            userId: response.data.data.id,
          },
        });
        return;
      } else if (response.data && response.data.data.id) {
        dispatch({
          type: "verified",
          action: {
            userId: response.data.data.id,
          },
        });
        return;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log({ e: error });
    }
    dispatch({ type: "rejected" });
  };

  const clearTokens = async () => {
    sessionStorage.removeItem(STORAGE_KEYS.token);
    sessionStorage.removeItem(STORAGE_KEYS.refreshToken);
  };

  useEffect(() => {
    verifyTokens();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        state,
        isPaused,
        setIsPaused,
        setSessionPin,
        sessionPin,
        setSessionData,
        setSessionId,
        sessionId,
        sessionData,
        clearTokens,
        setTokens,
        scopes,
        dispatch,
        apiBaseUrl,
        clientId,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

const useAuth = () => {
  const { apiBaseUrl, setTokens, clearTokens, state, dispatch, clientId } =
    useContext(ApiContext);
  const isPending = state.tokenStatus === STATUSES.PENDING;
  const isError = state.tokenStatus === STATUSES.ERROR;
  const isSuccess = state.tokenStatus === STATUSES.SUCCESS;
  const isVerified = state.tokenStatus === STATUSES.VERIFIED;
  const isAuthenticated = isVerified;
  const isProfileNotCompleted =
    state.tokenStatus === STATUSES.PROFILE_NOT_COMPLETED;

  //Rent Senter centercode validation
  const checkValidCenterCode = async (code) => {
    if (!code) {
      return;
    }
    const result = await fetch(apiBaseUrl + "/groups/accesskey", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({
        code: code,
        clientId: clientId,
      }),
    });
    const res = await result.json();
    return res;
  };

  const login = async ({ username, password }) => {
    const result = await fetch(apiBaseUrl + `/auth/otp`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username, password }),
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: !!res.success };
  };

  const logout = async () => {
    await clearTokens();
    localStorage.removeItem(STORAGE_KEYS.amplify);
    Object.keys(localStorage)
      .filter((key) => key.startsWith("CognitoIdentityServiceProvider"))
      .forEach((key) => localStorage.removeItem(key));
    dispatch({ type: "logout" });
  };

  const authenticate = async ({ crossToken }) => {
    const result = await fetch(apiBaseUrl + "/auth/crosstoken/" + crossToken, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
    });

    const { token, refreshToken } = await result.json();

    if (token && refreshToken) {
      dispatch({
        type: "crossToken-confirmed",
        action: { token, refreshToken },
      });
      setTokens({ refreshToken, token });

      // Check /me for userId
      const me = await fetch(apiBaseUrl + `/auth/me`, {
        headers: {
          Authorization: "jwt " + token,
        },
      });
      const res = await me.json();
      if (res.data && res.data.id) {
        dispatch({ type: "verified", action: { userId: res.data.id } });
        return { success: true };
      }
    }
    dispatch({ type: "rejected" });
    return { success: false };
  };

  const otp = async ({ username, otp }) => {
    const result = await fetch(apiBaseUrl + `/auth/jwt`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username, otp }),
    });

    const { token, refreshToken } = await result.json();

    if (token && refreshToken) {
      dispatch({ type: "otp-confirmed", action: { token, refreshToken } });
      setTokens({ refreshToken, token });

      // Check /me for userId
      const me = await fetch(apiBaseUrl + `/auth/me`, {
        headers: {
          Authorization: "jwt " + token,
        },
      });
      const res = await me.json();
      if (res.data && res.data.id) {
        dispatch({ type: "verified", action: { userId: res.data.id } });
        return { success: true };
      }
    }
    dispatch({ type: "rejected" });
    return { success: false };
  };

  const forgotPassword = async ({ username }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username }),
    });

    if (!result.ok) {
      return { success: false };
    }

    const res = await result.json();

    return { success: !!res.success };
  };

  const resetPassword = async ({ username, password, otp }) => {
    const result = await fetch(apiBaseUrl + `/auth/reset`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ username, password, otp }),
    });

    if (!result.ok) {
      return { success: false };
    }
    const res = await result.json();

    return { success: !!res.success };
  };

  const loginjwt = async (loginToken) => {
    try {
      const result = await fetchWithRetries(
        apiBaseUrl + `/auth/jwks/idToken/`,
        {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify({
            idToken: loginToken,
            clientId,
          }),
        }
      ).catch(error => {
        console.log(error);
        Sentry.captureMessage(`message: ${error?.message}`);
        Sentry.captureMessage(`code: ${error?.code}`);
        Sentry.captureMessage(`name: ${error?.name}`);
        Sentry.captureMessage(`status:${error?.status}`);
      });
      const { token, refreshToken } = await result.json();
      if (token && refreshToken) {
        dispatch({ type: "otp-confirmed", action: { token, refreshToken } });
        setTokens({ refreshToken, token });

        // Check /me for userId
        const me = await fetchWithRetries(apiBaseUrl + `/auth/me/`, {
          headers: {
            Authorization: "jwt " + token,
          },
        }).catch(error => {
          console.log(error);
          Sentry.captureMessage(`message: ${error?.message}`);
          Sentry.captureMessage(`code: ${error?.code}`);
          Sentry.captureMessage(`name: ${error?.name}`);
          Sentry.captureMessage(`status:${error?.status}`);
        });
        const res = await me.json();
        if (res.data && res.data.id) {
          if (res.data.profile_completed) {
            dispatch({ type: "verified", action: { userId: res.data.id } });
            return { success: true };
          }
          dispatch({
            type: "profile-not-completed",
            action: { userId: res.data.id },
          });
          return { success: false };
        }
      }
      dispatch({ type: "rejected" });
      return { success: false };
    } catch (error) {
      console.log(error);
      Sentry.captureException(error.message);
    }
  };

  return {
    ...state,
    isPending,
    isError,
    login,
    otp,
    logout,
    isSuccess,
    forgotPassword,
    resetPassword,
    authenticate,
    isAuthenticated,
    isProfileNotCompleted,
    loginjwt,
    checkValidCenterCode,
  };
};

const useGroups = () => {
  const { apiBaseUrl, scopes } = useContext(ApiContext);
  const { isAuthenticated, userId } = useAuth();
  const [groups, setGroups] = useState([]);

  const getGroup = async (id) => {
    const response = await http({
      url: `${apiBaseUrl}/groups/${id}`,
      method: "GET",
    });
    return response.data;
  };

  const getGroups = async () => {
    const response = await http({
      url: `${apiBaseUrl}/groups`,
      method: "GET",
    });
    console.log(response.data);
    setGroups(response.data.data);
    return response.data;
  };

  const getGroupsWithClientId = async () => {
    const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;
    const response = await http({
      url: `${apiBaseUrl}/groups/client/${clientId}`,
      method: "GET",
    });
    setGroups(response.data);
    return response.data;
  };

  return {
    getGroup,
    getGroups,
    getGroupsWithClientId,
    groups,
  };
};

const useUser = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const { isAuthenticated, userId } = useAuth();
  const [user, setUser] = useState(null);

  const userData = async ({ userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "GET",
    });
    const { firstName, lastName, email, profileImage, meta } =
      response.data.data.attributes;
    return {
      firstName,
      lastName,
      email,
      profileImage,
      meta,
    };
    // { attributes, relationships, included }
  };

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return;
    }
    const f = async () => {
      const { firstName, lastName, email, profileImage, meta } = await userData(
        {
          userId,
        }
      );
      setUser({
        id: userId,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
        profileImage,
        meta,
      });
    };
    f();
  }, [isAuthenticated, userId]);

  const addUserToGroups = async (groups, userId) => {
    let uniqueGroups = [...new Set(groups)];
    let requestData = uniqueGroups.map(g => {
      return {
        type: "groups",
        id: g,
      };
    });

    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "POST",
      data: { data: requestData },
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        Sentry.captureMessage(
          `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        Sentry.captureMessage(`request:${error.request}`);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        Sentry.captureMessage(`message:${error.message}`);
      }
      Sentry.captureException(error);
    });
    return response.data;
  };

  const removeUserFromGroups = async (groups, userId) => {
    let uniqueGroups = [...new Set(groups)];
    let requestData = uniqueGroups.map(g => {
      return {
        type: "groups",
        id: g.id,
      };
    });
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "DELETE",
      data: { data: requestData },
    });
    return response.data;
  };

  const getUserGroups = async (userId) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/groups`,
      method: "GET",
    });
    return response.data;
  };
  const getUserCourses = async (userId) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/courses`,
      method: "GET",
    });
    return response.data;
  };

  const getUserLearningpaths = async (userId) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/learningpaths`,
      method: "GET",
    });
    return response.data.data;
  };

  const getUserLearningpathWithStatuses = async ({
    userId,
    learningpathId,
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}/relationships/learningpaths/${learningpathId}`,
      method: "GET",
    });
    return response.data.data;
  };

  return {
    userId,
    user,
    addUserToGroups,
    removeUserFromGroups,
    getUserGroups,
    getUserCourses,
    getUserLearningpaths,
    getUserLearningpathWithStatuses,
  };
};

const useLMS = () => {
  const { apiBaseUrl } = useContext(ApiContext);
  const { isAuthenticated, userId, isProfileNotCompleted } = useAuth();
  const [courses, setCourses] = useState([]);
  const [attempt, setAttempt] = useState();
  const [learningpaths, setLearningPaths] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const update = async ({
    userId,
    email = "",
    firstName = "",
    lastName = "",
    profileImage,
    profileCompleted,
  }) => {
    console.log({ userId });
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "PATCH",
      data: {
        data: {
          type: "users",
          attributes: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            profileImage: profileImage,
            profileCompleted: profileCompleted,
          },
        },
      },
    });
    console.log({ response });
    setUpdateSuccess(true);
  };

  const createAttempt = async (userId, courseId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/`,
        method: "POST",
        data: {
          data: {
            userId,
            courseId,
          },
        },
      });
      console.log({ response });
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;
      if (response.data.data.attributes.status === "NOT ATTEMPTED")
        response.data.data.attributes.status = "INCOMPLETE";
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      console.log({ error });
    }
  };

  const getAttempt = async (attemptId) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "GET",
      });
      console.log({ response });
      // removing data we are not supposed to change clientside
      if (response.data.data.attributes.courseId != undefined)
        delete response.data.data.attributes.courseId;
      if (response.data.data.attributes.userId != undefined)
        delete response.data.data.attributes.userId;
      if (response.data.data.attributes.createdAt != undefined)
        delete response.data.data.attributes.createdAt;
      if (response.data.data.attributes.updatedAt != undefined)
        delete response.data.data.attributes.updatedAt;
      if (response.data.data.attributes.status === "NOT ATTEMPTED")
        response.data.data.attributes.status = "INCOMPLETE";

      console.log({ response });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      console.log({ error });
    }
  };

  const updateAttempt = async (attemptId, attributes) => {
    console.log({ attemptId });
    if (attemptId == undefined || attributes == undefined) return;
    try {
      const response = await http({
        url: `${apiBaseUrl}/attempts/${attemptId}`,
        method: "PATCH",
        data: {
          data: {
            id: attemptId,
            type: "attempts",
            attributes: attributes,
          },
        },
      });
      console.log({ response });
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      console.log({ error });
    }
  };

  const getDiplomasSent = async ({ userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/user/${userId}`,
      method: "GET",
    });
    return response.data;
  };

  const downloadDiploma = async ({ learningpathId, userId }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}`,
      method: "POST",
      data: {
        query: {
          locale: "nb-NO",
        },
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    return response;
  };

  const downloadRefresherDiploma = async ({
    learningpathId,
    refresherLearningpathId,
    userId,
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}/secondarylearningpath/${refresherLearningpathId}`,
      method: "POST",
      data: {
        query: {
          locale: "nb-NO",
        },
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    return response;
  };

  const forwardDiploma = async ({
    learningpathId,
    userId,
    to,
    finished = false,
  }) => {
    const response = await http({
      url: `${apiBaseUrl}/diploma/${userId}/learningpath/${learningpathId}/forward`,
      method: "POST",
      data: {
        query: {
          to,
          finished,
          locale: "nb-NO",
        },
      },
    });
    console.log({ response });
    return response;
  };

  const forwardSummary = async ({ courseId, userId }) => {
    try {
      const response = await http({
        url: `${apiBaseUrl}/diploma/${userId}/courses/${courseId}`,
        method: "POST",
      });
      console.log({ response });
      return response;
    } catch (error) {
      Sentry.captureException(error);
      console.log({ error });
      return null;
    }
  };

  const getOrganizations = async () => {
    const response = await http({
      url: `${apiBaseUrl}/organizations`,
      method: "GET",
    });
    return response.data.data;
    // { attributes, relationships, included }
  };
  const courseDetails = async () => {
    const response = await http({
      url: `${apiBaseUrl}/courses`,
      method: "GET",
    });
    return response.data;
    // { attributes, relationships, included }
  };
  const getLearningpaths = async () => {
    const response = await http({
      url: `${apiBaseUrl}/learningpaths`,
      method: "GET",
    });
    return response.data;
    // { attributes, relationships, included }
  };

  const getAssetUrl = async (id) => {
    const response = await http({
      url: `${apiBaseUrl}/assets/${id}`,
      method: "GET",
    });
    return response.data;
  };

  const deleteAsset = async (id) => {
    const response = await http({
      url: `${apiBaseUrl}/assets/${id}`,
      method: "DELETE",
    });
    return response;
  };

  const getImage = async (id) => {
    const response = await http({
      url: `${apiBaseUrl}/assets/` + id,
      method: "GET",
    });
    return response.data;
  };

  const getUploadUrl = async (filename) => {
    let org = organizations.find(
      (organization) => organization?.attributes?.meta?.shortname == "adno"
    );
    console.log({ org });

    const response = await http({
      url: `${apiBaseUrl}/assets`,
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      data: {
        data: {
          attributes: {
            filename: filename,
          },
          relationships: {
            organization: {
              data: {
                id: org.id,
                type: "organizations",
              },
            },
          },
        },
      },
    });
    return response.data;
  };

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      return;
    }
    const f = async () => {
      const courseDet = await courseDetails();
      console.log("setCourses()", courseDet);
      setCourses(courseDet);
      const orgs = await getOrganizations();
      setOrganizations(orgs);
      console.log({ orgs });
      /*let org = orgs.find(
        (organization) => organization?.attributes?.meta?.shortname == "adno"
      );
      console.log({ org });*/
    };
    f();
  }, [isAuthenticated, userId]);

  useEffect(() => {
    if (!isProfileNotCompleted) {
      return;
    }
    const f = async () => {
      const orgs = await getOrganizations();
      setOrganizations(orgs);
      /*let org = orgs.find(
        (organization) => organization?.attributes?.meta?.shortname === "adno"
      );*/
    };
    f();
  }, [isProfileNotCompleted]);

  return {
    courses,
    courseDetails,
    getUploadUrl,
    getLearningpaths,
    organizations,
    getAssetUrl,
    createAttempt,
    getAttempt,
    updateAttempt,
    getImage,
    deleteAsset,
    downloadDiploma,
    downloadRefresherDiploma,
    forwardDiploma,
    forwardSummary,
    getDiplomasSent,
  };
};

const useUsers = () => {
  const { apiBaseUrl, dispatch } = useContext(ApiContext);
  const [userId, setUserId] = useState();
  const [usersData, setUsersData] = useState([]);
  const [updateSuccess, setUpdateSuccess] = useState();

  const create = async ({ firstName, lastName }) => {
    const response = await http({
      url: `${apiBaseUrl}/users`,
      method: "POST",
      data: {
        firstName,
        lastName,
      },
    });
    setUserId(response.data.data.id);
  };

  const patchUser = async ({ userId, firstName, lastName }) => {
    console.log({ userId });
    const uploadData = {
      type: "users",
      id: userId,
      attributes: {
        firstName,
        lastName,
      },
    };
    console.log({ uploadData });
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "PATCH",
      data: {
        data: uploadData,
      },
    });
    console.log({ response });
    setUpdateSuccess(true);
    return response;
  };

  const updateLanguage = async ({ userId, language }) => {
    console.log("updateLanguage()");
    console.log("userId", userId);
    console.log("language", language);
    let response;
    if (userId != undefined && language != undefined) {
      try {
        response = await http({
          url: `${apiBaseUrl}/users/${userId}`,
          method: "PATCH",
          data: {
            data: {
              type: "users",
              id: userId,
              attributes: {
                language,
              },
            },
          },
        });
      } catch (error) {
        Sentry.captureException(error);
        console.log({ error });
      }
    }

    console.log({ response });
    return response;
  };

  const updateEmail = async ({ userId, email }) => {
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "PATCH",
      data: {
        data: {
          type: "users",
          id: userId,
          attributes: {
            email,
          },
        },
      },
    });
    console.log({ response });
    return response;
  };

  const update = async ({
    userId,
    email = "",
    firstName = "",
    lastName = "",
    profileImage,
    profileCompleted,
    language = "",
    meta = {},
  }) => {
    const uploadData = profileImage
      ? {
          type: "users",
          id: userId,
          attributes: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            profileImage: profileImage,
            profileCompleted: profileCompleted,
          },
        }
      : {
          type: "users",
          id: userId,
          attributes: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            language: language,
            profileCompleted: profileCompleted,
            meta: meta,
          },
        };
    const response = await http({
      url: `${apiBaseUrl}/users/${userId}`,
      method: "PATCH",
      data: {
        data: uploadData,
      },
    });
    if (response.status === 200 || response.status === 204) {
      dispatch({
        type: "verified",
        action: {
          userId: userId,
        },
      });
    }
    console.log({ response });
    setUpdateSuccess(true);
  };

  const loadUsers = async ({ filter } = {}) => {
    let url = `${apiBaseUrl}/users`;
    if (filter && filter.activated) {
      url = `${apiBaseUrl}/users?filter[activated]=true`;
    }
    const response = await http({
      url,
      method: "GET",
    });
    setUsersData(response.data);
    return response.data;
  };

  const loadUser = async ({ userId }) => {
    let url = `${apiBaseUrl}/users/${userId}`;
    const response = await http({
      url,
      method: "GET",
    });

    return response.data;
  };

  const deleteUser = async ({ userId }) => {
    let url = `${apiBaseUrl}/users/${userId}`;
    const response = await http({
      url,
      method: "DELETE",
    });

    return response;
  };

  return {
    userId,
    create,
    update,
    loadUsers,
    usersData,
    patchUser,
    loadUser,
    deleteUser,
    updateEmail,
    updateLanguage,
  };
};

export { ApiProvider, useAuth, useGroups, useLMS, useUser, useUsers };

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

import { Global } from "@emotion/react";
const Fonts = () => (
  <Global
    styles={`
      /* DIN Round W01 Bold */
      @font-face {
        font-family: 'DIN Round W01 Bold';
        font-display: swap;
        src: url('./fonts/DINRoundW01Regular.woff2') format('woff2'), url('./fonts/DINRoundW01Regular.woff') format('woff');
      }
      /* DIN Round W01 Regular */
      @font-face {
        font-family: 'DIN Round W01 Regular';
        font-display: swap;
        src: url('./fonts/DINRoundW01Regular.woff2') format('woff2'), url('./fonts/DINRoundW01Regular.woff') format('woff');
      }
      `}
  />
);
export default Fonts;

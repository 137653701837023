import { extendTheme } from "@chakra-ui/react";
import { Button } from "./components/Button";
import { Input } from "./components/Input";
import { Select } from "./components/Select";
import { Checkbox } from "./components/Checkbox";
import { IconButton } from "./components/IconButton";
import { colors } from "./colors";
import { breakpoints } from "./breakpoints";
import { styles } from "./styles";

const overrides = {
  breakpoints,
  styles,
  colors,
  fonts: {
    heading: "HCo Gotham",
    body: "DIN Round W01 Regular",
  },
  components: {
    Button,
    Input,
    Select,
    Checkbox,
    IconButton,
    // Other components go here
  },
};

const theme = extendTheme(overrides);

export default theme;

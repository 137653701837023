import React from "react";
import { Heading, Flex } from "@chakra-ui/react";

const BrandTitle = ({ titlePartOne, titlePartTwo }) => {
  return (
    <Flex pb={6} width="100%" justify="center" align="center">
      <Heading
        as="h1"
        fontSize={{ sm: "3em", md: "5em", lg: "6em" }}
        fontWeight="400"
        textTransform="uppercase"
      >
        <mark>{titlePartOne}</mark>
        {titlePartTwo}
      </Heading>
    </Flex>
  );
};

export default BrandTitle;

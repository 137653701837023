import { Box } from "@chakra-ui/react";

const ScreenContainer = ({ children }) => {
  return (
    <Box p={4} maxW="714px" margin="0 auto">
      {children}
    </Box>
  );
};

export default ScreenContainer;

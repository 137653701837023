import React, { createContext, useState, useEffect } from "react";

const config = {
  language: null,
  isAuthenticated: false,
  languages: [],
  setLanguage: () => {},
  setIsAuthenticated: () => {},
};

export const UserContext = createContext(config);

export const UserProvider = ({ children }) => {
  // supported languages, [0] is default
  const languages = ["en", "fr", "de", "it"];
  const [language, setLanguage] = useState(() => {
    if (typeof window !== "undefined") {
      const localValue = JSON.parse(localStorage.getItem("language"));
      const activeLanguage =
        localValue && languages.includes(localValue)
          ? localValue
          : languages[0];
      return activeLanguage;
    } else {
      // default language
      return languages[0];
    }
  });

  const storeLanguage = (lang) => {
    if (lang && typeof window !== "undefined") {
      localStorage.setItem("language", JSON.stringify(lang));
      setLanguage(lang);
    }
    return;
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let local = localStorage.getItem("language");
      if (local && languages.includes(local)) {
        storeLanguage(local);
        return;
      }
    }
    return;
  }, []);
  console.log("Language: ", language);

  return (
    <UserContext.Provider value={{ storeLanguage, language, languages }}>
      {children}
    </UserContext.Provider>
  );
};

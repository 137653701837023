import { ChakraProvider } from "@chakra-ui/react";
import { ApiProvider } from "src/hooks/api";
import { UserProvider } from "src/context/UserContext";
import { RouteGuard } from "src/components";
import theme from "src/theme";
import Fonts from "src/theme/fonts";
import "../styles/globals.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as Fathom from "fathom-client";

if (process.env.NODE_ENV === "development") {
  console.log("dev");
  //require("../mocks");
}

const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const clientId = process.env.NEXT_PUBLIC_CLIENT_ID;

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    Fathom.load("DEYBYBWQ", {
      includedDomains: ["ch.cleanwinner.org"],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  return (
    <ApiProvider
      config={{
        apiBaseUrl,
        clientId,
      }}
    >
      <UserProvider>
        <ChakraProvider theme={theme}>
          <RouteGuard>
            <Fonts />
            <Component {...pageProps} />
          </RouteGuard>
        </ChakraProvider>
      </UserProvider>
    </ApiProvider>
  );
}

export default MyApp;

export const IconButton = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: "semibold",
    //boxShadow: "0 0 2px 2px #efdfde",
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    xl: {
      h: "3em",
      fontSize: "lg",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    transparent: {
      bg: "primaryDarker",
      color: "baseLight",
    },
  },
};

import { Flex, Text, Spinner } from "@chakra-ui/react";

export default function LoadingSpinner() {
  return (
    <Flex
      height="100vh"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size="xl" thickness="7px" />
      <Text>Loading...</Text>
    </Flex>
  );
}

import React from "react";
import { Box, Flex, Text, HStack, Image } from "@chakra-ui/react";

const CourseCard = ({
  disabled,
  title,
  startCourse,
  course,
  status,
  icon,
  downloadIcon,
}) => {
  return (
    <Flex
      p={3}
      justify="space-between"
      align="center"
      bgImage="/bg.svg"
      bgRepeat="no-repeat"
      opacity={disabled ? "50%" : "100%"}
      cursor="pointer"
      onClick={(event) => {
        startCourse(course);
        event.target.blur();
      }}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <HStack spacing="25px">
        <Box
          p={1}
          opacity={` ${
            status?.status && status?.status === "PASSED" ? 0.5 : 1
          }`}
        >
          <Image
            src={icon ? "/modules/" + icon + ".png" : "modules/checkmark.png"}
            alt="module icon"
            objectFit="contain"
            w="35px"
          />
        </Box>
        <Text color="baseLight" fontWeight="semibold" textTransform="uppercase">
          {title}
        </Text>
      </HStack>
      <Image
        src={
          downloadIcon ? "modules/" + downloadIcon + ".png" : "modules/play.png"
        }
        alt="Start module"
        objectFit="contain"
        w="40px"
      />
    </Flex>
  );
};

export default CourseCard;

export const colors = {
  baseLight: "#fff", // brightest color in project
  baseDark: "#000", // darkest color in project
  primary: "#571e52", // main color (purple)
  primaryDarker: "#312941",
  buttonBackground: "#e5e5e5", // grey button
  buttonText: "#312941", // dark grey
  buttonHover: "#ccc", //middle grey
  buttonActive: "#666", // darker grey
  link: "#337ab7", // blue
  linkHover: "#23527c", // dark blue
  errorMessage: "#a94442",
};

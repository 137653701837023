import React from "react";
import { Flex, Text, HStack, Image } from "@chakra-ui/react";

const DiplomaCard = ({
  title,
  icon,
  downloadIcon,
  downloadDiploma,
  userId,
  learningpathId,
  refresherLearningpathId,
  disabled,
}) => {
  const onDownloadDiploma = async (event) => {
    event.preventDefault();
    let response = await downloadDiploma({
      userId,
      learningpathId,
      refresherLearningpathId,
    });
    // creating a hidden <a> tag
    var a = document.createElement("a");

    // creating a reference to the file
    var url = window.URL.createObjectURL(response.data);

    // setting anchor tag's href attribute to the blob's URL
    a.href = url;

    // setting anchor tag's download attribute to the filename
    a.download = "diploma.pdf";
    document.body.append(a);

    a.click();

    // after clicking it, remove it from the DOM
    a.remove();
    // release an existing object URL which was previously
    // created by calling URL.createObjectURL()
    // once we have finished using an object URL, let the
    // browser know not to keep the reference to the file any longer.
    window.URL.revokeObjectURL(url);
  };

  return (
    <Flex
      p={3}
      justify="space-between"
      align="center"
      bgImage="/bg.svg"
      bgRepeat="no-repeat"
      opacity={disabled ? "50%" : "100%"}
      cursor="pointer"
      onClick={onDownloadDiploma}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <HStack spacing="30px">
        <Image
          src={"/modules/" + icon + ".png"}
          alt="module icon"
          objectFit="contain"
          w="35px"
        />
        <Text color="baseLight" fontWeight="semibold" textTransform="uppercase">
          {title}
        </Text>
      </HStack>
      <Image
        src={"/modules/" + downloadIcon + ".png"}
        alt="Download diploma"
        objectFit="contain"
        w="40px"
      />
    </Flex>
  );
};

export default DiplomaCard;
